import { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Container, Section } from '../components/Base';
import Emojis, { TYPE_PREMIUM } from '../components/Emojis';
import NewsletterForm from '../components/NewsletterForm';
import { getTitle } from '../utils/document';

export default class NewsletterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      account: null,
    };
  }

  render() {
    return (
      <Section className="newsletterpage">
        <Helmet>
          <title>{getTitle('Newsletter')}</title>
        </Helmet>
        <Container>
          <h1>Stay in touch</h1>
          <Emojis type={TYPE_PREMIUM} />
          <NewsletterForm />
          <br />
          <br />
          <img
            className="resp opacity-90"
            alt="High five!"
            src="https://gofullpage.com/static/email/highfive.gif"
          />
        </Container>
      </Section>
    );
  }
}
