import classNames from 'classnames';
import { FunctionComponent, useRef, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { MAILCHIMP_URL } from '../constants';
import { log } from '../logger';

const PLEA_TEXT =
  'Join our newsletter to keep up with the latest product updates:';

type NewsletterFormProps = {
  className?: string;
  btnClassName?: string;
  pClassName?: string;
  initialEmail?: string;
  onSuccess?: () => void;
  doubleOptIn: boolean;
};

const NewsletterForm: FunctionComponent<NewsletterFormProps> = ({
  className,
  btnClassName,
  pClassName,
  initialEmail,
  onSuccess,
  doubleOptIn,
}) => {
  const [email, setEmail] = useState(initialEmail || '');

  const handleChangeInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const targ = evt.currentTarget;
    log(`[${targ.name}]: ${targ.value}`);
    setEmail(targ.value);
  };

  const didSuccessRef = useRef(false);

  const handleSuccess = () => {
    if (didSuccessRef.current) {
      return;
    }
    didSuccessRef.current = true;
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={({ subscribe, status, message }) => {
        if (status === 'success') {
          handleSuccess();
        }
        return (
          <div className={classNames('newsletterform', className)}>
            <p className={classNames(pClassName, 'text-pretty')}>{PLEA_TEXT}</p>
            <form
              className="pure-form pure-form-center-spaced mb-5"
              onSubmit={(evt) => {
                evt.preventDefault();
                subscribe({ EMAIL: email });
              }}
            >
              <input
                type="text"
                className=""
                name="email"
                onChange={handleChangeInput}
                placeholder="you@example.com"
              />
              <button
                className={classNames(
                  btnClassName,
                  'pure-button pure-button-primary pure-button-small',
                )}
              >
                Submit
              </button>
            </form>
            {status === 'sending' && <div className="msg">sending...</div>}
            {status === 'error' && (
              <div
                className="msg error"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            {status === 'success' && (
              <div className="msg">
                {doubleOptIn
                  ? 'Success, a double opt-in confirmation email is on its way!'
                  : 'Subscribed!'}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

NewsletterForm.displayName = 'NewsletterForm';

export default NewsletterForm;
