import { getIsEdge } from './sniff';
import { AbTestV2 } from './types/Account';

const captureVideoMp4Src =
  require('../assets/video/fpsc-sample-2.mp4') as string;
const captureVideoPosterSrc =
  require('../assets/video/fpsc-sample-2.jpg') as string;

export const ENV = process.env.ENV;

export const IS_DEV = ENV === 'dev';
export const IS_MASTER = !ENV || ENV === 'master';
if (!ENV) {
  try {
    console.log('%cWARNING ENV NOT SET', 'color:#fff;background:red;');
  } catch (e) {}
}

export const IS_EDGE = getIsEdge();

export const BASE_TITLE = 'GoFullPage';

export const BASE_TITLE_SEP = ' | ';

export const SUPPORT_EMAIL = 'support@gofullpage.com';

export const ENTERPRISE_SUPPORT_EMAIL = 'enterprise@gofullpage.com';

export const PATH_CHECKOUT = '/checkout';

export const CAPTURE_VIDEO = {
  srcMp4: captureVideoMp4Src,
  poster: captureVideoPosterSrc,
  width: 2128,
  height: 1236,
};

export const EDITOR_VIDEO = {
  // srcMp4: editorVideoMp4Src,
  // poster: editorVideoPosterSrc,

  // ## Apr 2022 update
  youtube: 'https://www.youtube.com/embed/Lb7zCXPUTk0?rel=0',
  width: 526.25, // 1806 x 1080 AKA (526.25 x 315)
  height: 315,

  // ## old pre Apr 2022
  // youtube: 'https://www.youtube.com/embed/JPp0l6v4AQk?rel=0',
  // width: 560, // 2196,
  // height: 315, // 1224
};

// <iframe width="560" height="315" src="https://www.youtube.com/embed/JPp0l6v4AQk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

// @ts-ignore
export const SENTRY_DSN = process.env.SENTRY_DSN;

export const STATS = {
  users: '8M+',
  usersLong: '8,000,000+',
  ratings: '73K',
  stars: '4.9',
};

// storage
export const STORE_KEY_AB = '__fpab';

// for scrolling to a position
export const HEADER_OFFSET = 100;

export const WEBSTORE_LINK_CHROME =
  'https://chrome.google.com/webstore/detail/full-page-screen-capture/fdpohaocaechififmbbbbbknoalclacl';
export const WEBSTORE_LINK_EDGE =
  'https://microsoftedge.microsoft.com/addons/detail/gofullpage-full-page-sc/hfaciehifhdcgoolaejkoncjciicbemc';

export const WEBSTORE_LINK = IS_EDGE
  ? WEBSTORE_LINK_EDGE
  : WEBSTORE_LINK_CHROME;

let _TEST_EXTENSION_ID = null;

if (!IS_MASTER) {
  let extId: string;
  try {
    // @ts-ignore
    extId = window.localStorage.getItem('__fpextid');
  } catch (e) {
    console.log(`[LOAD_EXT_ID] ERROR`, e);
  }

  if (
    // @ts-ignore
    extId &&
    extId.length === 'bdcddgmfgleacjaapnhfehmicmcgjjkp'.length &&
    /^[a-z]+$/.test(extId)
  ) {
    _TEST_EXTENSION_ID = extId;
  }
}

// TODO(EDGE) - we cannot have one EXTENSION_ID anymore!!
export const EXTENSION_ID =
  _TEST_EXTENSION_ID ||
  // @ts-ignore
  process.env.EXTENSION_ID ||
  'fdpohaocaechififmbbbbbknoalclacl';

export const EXTENSION_ID_TEST = _TEST_EXTENSION_ID;

export const EXTENSION_ID_CHROME = 'fdpohaocaechififmbbbbbknoalclacl';
export const EXTENSION_ID_EDGE = 'hfaciehifhdcgoolaejkoncjciicbemc';

// for communication with extension
export const EXTENSION_ORIGINS = [];

if (EXTENSION_ID_TEST) {
  // @ts-ignore
  EXTENSION_ORIGINS.push(`chrome-extension://${EXTENSION_ID_TEST}`);
} else {
  if (IS_EDGE) {
    // NOTE - edge shows the URL as `extension://asdf`, but window.location.href shows `chrome-extension://asdf`
    // @ts-ignore
    EXTENSION_ORIGINS.push(`chrome-extension://${EXTENSION_ID_EDGE}`);
  }
  // @ts-ignore
  EXTENSION_ORIGINS.push(`chrome-extension://${EXTENSION_ID_CHROME}`);
}

// uncomment this to test the multi-origin resolution in extension/Provider.js
// EXTENSION_ORIGINS.unshift('chrome-extension://asdfasdf');

export const EXTENSION_APIS = EXTENSION_ORIGINS.map((x) => `${x}/p/_api.html`);

export const EXTENSION_LANDING_PATH = '/p/index.html';

export const MAILCHIMP_URL =
  // @ts-ignore
  process.env.MAILCHIMP_URL ||
  'https://gofullpage.us20.list-manage.com/subscribe/post?u=dcefee0c7f4257e92f87a6d73&id=5827e44318&f_id=004819eff0';

// AB Test V2

export const IS_ABTESTV2_ENABLED = true;

export const CHECKOUT_TYPE_STRIPE = 'stripe'; // DUPED in backend shared/constants.js (and AbTestV2)
export const CHECKOUT_TYPE_CLASSIC = 'classic'; // DUPED in backend shared/constants.js (and AbTestV2)

/**
 * Get an the GA experiment id for a test group
 */
type GroupToExps = { [key in AbTestV2['group']]: string };

const V2_GROUP_TO_EXP_DEV: GroupToExps = {
  stripe: 'zEx_teqpQ-ySI0QIFbgqZQ.1',
  classic: 'zEx_teqpQ-ySI0QIFbgqZQ.0',
};
const V2_GROUP_TO_EXP_PROD: GroupToExps = {
  stripe: 'DM2mDCHfS8apGSC9eiqHFQ.1',
  classic: 'DM2mDCHfS8apGSC9eiqHFQ.0',
};

export const abTestV2GroupToGaExp = (
  group: AbTestV2['group'],
): string | undefined => {
  const groupToExp = IS_MASTER ? V2_GROUP_TO_EXP_PROD : V2_GROUP_TO_EXP_DEV;
  return groupToExp[group];
};

// Query string params

/** query string param to set on /account when coming from a successful org memeber invite signup */
export const QS_ORG_ACCOUNT_SUCCESS = 'success';
